* {
  font-family: "Work Sans", sans-serif;
  box-sizing: border-box;
}

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
}

h1 {
  font-size: 44px !important;
  line-height: 2.8rem;
}

h2 {
  font-size: 32px;
  line-height: 2.4rem;
}

h3 {
  font-size: 20px;
  font-weight: 400;
}

h4 {
  font-size: 18px;
}

.main-margin {
  margin-left: 10rem;
  margin-right: 10rem;
}

.pt-6 {
  padding-top: 6rem;
}

.pt-8 {
  padding-top: 8rem;
}

li {
  display: flex;
  align-items: center;
}

ul {
  padding: 0;
}

p {
  margin: 0;
}

.button,
.button:focus {
  background-color: var(--primary-bg-color);
  border: none;
  color: white;
  margin: 0 20px 0 20px;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(59, 53, 53);
  cursor: pointer;
}

.line {
  margin-bottom: 8px !important;
}

.container {
  position: relative;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.horizontal-center {
  text-align: center;
}

.bold-text {
  font-weight: bold;
}

@media (min-width: 750px) {
  .top-margin {
    height: 3rem;
  }
}

.nav-link {
  color: black;
  font-weight: 400;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: transparent;
}

.nav-link .nav-tabs {
  border-color: transparent;
}

.nav-link.active {
  font-weight: 700;
  border-bottom: 2px solid var(--primary-bg-color) !important;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  border-color: transparent;
  color: black;
}

button.nav-link {
  font-weight: 401 !important;
}

button.nav-link.active {
  font-weight: 601 !important;
}

.circle {
  border-radius: 50px;
}

.click-icon {
  color: white;
  font-weight: bold;
}

.half-right {
  transform: translateX(-45%);
}

.double-width {
  width: 200%;
}

.check-failure,
.check-success {
  background-color: #222222;
}

textarea {
  background-color: #fafafa !important;
  border: none !important;
  resize: none !important;
}

::placeholder {
  margin: 0;
  position: absolute;
  top: 46%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 50%;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.p-timeline-event {
  display: block;
}

.p-timeline.p-timeline-vertical .p-timeline-event-content {
  height: 80px;
}

@media (max-width: 750px) {
  .p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
  .p-timeline.p-timeline-horizontal .p-timeline-event-content {
    padding: 0;
    margin: 0 auto;
  }
}

#file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

@media (min-width: 970px) {
  .verification-section {
    width: 100%;
  }
  .verification-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 970px) {
  .verification-section {
    width: 100%;
    padding: 0;
  }

  .verification-container {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 2rem;
  }
}

/* @media (max-width: 600px) {
  .stepper {
    display: none;
  }
} */

.validateButton:disabled {
  opacity: 50%;
  cursor: no-drop;
}

.p-progress-spinner-svg {
  width: 60%;
}

.p-progress-spinner-circle {
  stroke: #06d7be !important;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite;
}
.p-button,
.p-button:hover {
  background: transparent !important;
  color: #2b2b2b !important;
  border: none !important;
  box-shadow: none !important;
}

.p-card {
  box-shadow: 2px 3px 10px 2px #eeeeee;
}

.height-100 {
  height: 100%;
}

.img-contain {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.pdf-viewer canvas {
  width: 100% !important;
  height: auto !important;
  max-height: 450px !important;
}

.color-success {
  color: #36b37e;
}

.color-error {
  color: #f65531;
}

.p-accordion-content {
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
  border: none !important;
  padding: 0 !important;
}

.p-accordion-header-link {
  border: none !important;
  padding: 0 !important;
  padding-top: 1rem !important;
}

.react-pdf__Page__annotations.annotationLayer {
  display: none !important;
}

/* Vertical Timeline */

.p-timeline-event {
  display: flex;
}

.p-timeline-event-content {
  flex: 3 0;
  padding: 0 1rem;
}

.stepper {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-bottom: 4rem !important;
}

.p-timeline-event-opposite {
  flex: 0 1;
}

.p-accordion-header-link {
  padding-bottom: 10px !important;
}

@media (max-width: 670px) {
  .features {
    display: none;
  }
  .container {
    height: 180px !important;

    outline: none !important;
    width: 100% !important;
    margin: 20px 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
  }
}
.container {
  max-width: 100%;
}
.networks-cards-info {
  display: block;
}

.networks-table-info {
  display: none;
}

@media (min-width: 671px) {
  .networks-table-info {
    display: block;
  }
  .networks-cards-info {
    display: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: inherit;
}

.p-accordion
  .p-accordion-header:not(.p-disabled).p-highlight:hover
  .p-accordion-header-link,
.p-accordion,
.p-accordion-tab,
.p-accordion-header,
.p-accordion-tab:last-child,
.p-accordion-header:not(.p-highlight),
.p-accordion-header-link {
  background-color: inherit;
}
.p-accordion-header-text {
  font-size: 0.9rem;
}

.p-accordion-toggle-icon,
.pi-chevron-down,
.pi-chevron-right {
  font-size: 0.6rem !important;
  font-weight: 600;
}

.p-accordion
  .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
  .p-accordion-header-link {
  background-color: inherit;
}

:focus {
  outline: 0 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.preview-btn,
.preview-btn::after {
  background-color: transparent;
  border: none;
  padding: 0 15px;
}

.alert {
  padding: 0.7rem 0.7rem;
}

.p-dropdown-panel,
.p-dropdown-items,
.p-dropdown-item.p-highlight {
  background: "gray" !important;
}

.p-dropdown,
.p-component,
.p-inputwrapper,
.p-inputwrapper-filled,
.p-inputwrapper-focus,
.p-dropdown-trigger,
.p-dropdown:not(.p-disabled):hover {
  outline: none !important;
  box-shadow: none !important;
  border-color: gray;
}

.dropdown-item,
.dropdown-item:active,
.dropdown-item.active,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  background-color: inherit !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.downloadBtn {
  filter: invert(25%) sepia(3%) saturate(14%) hue-rotate(350deg) brightness(93%)
    contrast(85%);
}
.downloadBtn:hover {
  filter: invert(31%) sepia(0%) saturate(1161%) hue-rotate(224deg)
    brightness(97%) contrast(91%);
  cursor: pointer;
}
